import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Radio } from 'seedly-component-library';
import Button from 'seedly-component-library/button-new';
import Dialog from 'seedly-component-library/dialog';
import { Group } from 'entity/group/groupRedux';
import { useIsSignedIn } from 'entity/user/userHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'reducer';
import {
  useSelectGroupListByName,
  useSelectFollowedGroupListByJoinedAt,
  useSelectNotFollowedGroupListByName,
} from 'entity/group/groupHooks';
import GroupPassport from 'components/group/GroupPassport';

const GroupModal = props => {
  const { isOpen, setGroupModalOpen, setFieldValue, values } = props;
  const allGroups = useSelectGroupListByName();
  const followedGroups = useSelectFollowedGroupListByJoinedAt();
  const notFollowedGroups = useSelectNotFollowedGroupListByName();
  const isSignedIn = useIsSignedIn();
  const profile = useSelector((state: RootState) => state.profile.user);

  const [selectedGroup, setSelectedGroup] = useState<Group>(null);

  const isRadioButtonChecked = group => {
    if (isEmpty(selectedGroup)) return false;
    const hasInputBeenSelected = selectedGroup.id === group.id;
    return hasInputBeenSelected;
  };

  const handleSelectGroup = group => {
    setSelectedGroup(group);
  };

  const handleCloseModal = () => {
    setGroupModalOpen(() => false);
    setSelectedGroup(null);
  };

  const submitSelectedGroup = () => {
    if (selectedGroup) {
      setFieldValue('tags', []);
      setFieldValue('group', selectedGroup);
    }
    setGroupModalOpen(() => false);
  };

  useEffect(() => {
    setSelectedGroup(values.group);
  }, [values.group]);

  const displayAllGroups = allGroups.map(group => {
    return (
      <Radio
        name="groups"
        label={group.slug}
        onChange={() => handleSelectGroup(group)}
        key={group.id}
        isChecked={isRadioButtonChecked(group)}
      >
        <GroupPassport
          name={group.name}
          image={group.profileImage}
          discussionsCount={group.discussionsCount}
        />
      </Radio>
    );
  });

  const displayMyGroups = followedGroups
    .filter(group => {
      if (!profile.officialAccount && group.slug === 'announcements')
        return false;
      return true;
    })
    .map(followedGroup => {
      return (
        <Radio
          name="groups"
          label={followedGroup.slug}
          onChange={() => handleSelectGroup(followedGroup)}
          key={followedGroup.id}
          isChecked={isRadioButtonChecked(followedGroup)}
        >
          <GroupPassport
            name={followedGroup.name}
            image={followedGroup.profileImage}
            discussionsCount={followedGroup.discussionsCount}
          />
        </Radio>
      );
    });

  const displayNotFollowedGroups = notFollowedGroups.map(notFollowedGroup => {
    return (
      <Radio
        name="groups"
        label={notFollowedGroup.slug}
        onChange={() => handleSelectGroup(notFollowedGroup)}
        key={notFollowedGroup.id}
        isChecked={isRadioButtonChecked(notFollowedGroup)}
      >
        <GroupPassport
          name={notFollowedGroup.name}
          image={notFollowedGroup.profileImage}
          discussionsCount={notFollowedGroup.discussionsCount}
        />
      </Radio>
    );
  });

  return (
    <Dialog
      isOpen={isOpen}
      onCloseClick={handleCloseModal}
      headerTitle="Select a group"
      headerClassName="p-4 border-b border-neutral-400"
      contentClassName="p-0"
    >
      <div className="h-full lg:h-[50vh] overflow-y-scroll p-4">
        {isSignedIn && followedGroups.length > 0 && (
          <>
            <div className="my-4">
              <p className="text-sm font-bold">My Groups</p>
            </div>
            <div className="flex flex-col gap-4">{displayMyGroups}</div>
          </>
        )}
        {notFollowedGroups.length > 0 && (
          <div className="my-4">
            <p className="text-sm font-bold">
              {isSignedIn && followedGroups.length > 0
                ? 'Post to Other Groups'
                : 'All Groups'}
            </p>
            <p className="text-xs text-neutral-500">
              You will automatically become a member of the group after posting
            </p>
          </div>
        )}
        <div className="flex flex-col gap-4">
          {isSignedIn && followedGroups.length > 0
            ? displayNotFollowedGroups
            : displayAllGroups}
        </div>
      </div>
      <div className="flex gap-2 items-center justify-end w-full p-3 border-t border-neutral-400 sticky lg:relative bottom-0 z-10 bg-white rounded-b-2xl">
        <Button variant="outline" onClick={handleCloseModal}>
          Back
        </Button>
        <Button onClick={submitSelectedGroup} disabled={isEmpty(selectedGroup)}>
          Select
        </Button>
      </div>
    </Dialog>
  );
};

export default GroupModal;
