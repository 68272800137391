export const isSeedlyEventSlug = (slug: string) => {
  if (slug === 'seedlytv' || slug === 'seedlyama') {
    return true;
  }

  return false;
};

export const getSeedlyEventTitle = (slug: string) => {
  if (slug === 'seedlytv') {
    return 'Episodes';
  }

  if (slug === 'seedlyama') {
    return 'Sessions';
  }

  return '';
};

export const getSeedlyEventPlaceholder = (slug: string) => {
  if (slug === 'seedlytv') {
    return 'Choose an episode';
  }

  if (slug === 'seedlyama') {
    return 'Choose a session';
  }

  return '';
};

export const filterGroups = groups => {
  const topThreeGroups = groups.filter(group => {
    return (
      group.name === 'General Investing' ||
      group.name === 'Insurance' ||
      group.name === 'Stocks'
    );
  });

  const restOfGroups = groups.filter(group => {
    return (
      group.name !== 'General Investing' &&
      group.name !== 'Insurance' &&
      group.name !== 'Stocks'
    );
  });

  return {
    topThreeGroups,
    restOfGroups,
  };
};
