export const getTotalWordCount = text => {
  if (!text || typeof text !== 'string') return null;

  const totalWordCount = text.split('/')[0];

  return parseInt(totalWordCount, 10);
};

export const getMaximumWordCount = text => {
  if (!text || typeof text !== 'string') return null;

  const maxWordCount = text.split('/')[1];

  return parseInt(maxWordCount, 10);
};

/**
 * @param {number} totalWordCount - all the characters the user typed
 * @param {number} maxWordCount - the limit for the characters
 * @param {string} wordLeftDesc - the words for when the characters have exceeded
 * @returns error message when the words over the maximum word count
 */
export const displayErrorMessage = (
  totalWordCount,
  maxWordCount,
  wordLeftDesc,
) => {
  if (!totalWordCount || !maxWordCount)
    return `You have exceeded the ${wordLeftDesc}`;

  return `You have exceeded by ${
    totalWordCount - maxWordCount
  } ${wordLeftDesc}`;
};
