import { useState, useEffect } from 'react';
import productApi from 'app/api/productApi';

const usePopularProducts = () => {
  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    const popularProductsCache =
      JSON.parse(sessionStorage.getItem('popularProducts')) || [];

    const fetchPopularProducts = async () => {
      const response = await productApi.getTrendingProducts();
      if (response.ok) {
        const productList =
          response.data?.data.map(product => product.name) || {};
        sessionStorage.setItem('popularProducts', JSON.stringify(productList));
        setPopularProducts(productList);
      }
    };

    if (popularProductsCache.length === 0) {
      fetchPopularProducts();
    } else {
      setPopularProducts(popularProductsCache);
    }
  }, []);

  return popularProducts;
};

export default usePopularProducts;
