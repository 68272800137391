import React, { useState, useRef } from 'react';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import { TagInput, Tag } from 'app/seedly-component-library';
import { Tag as ProductTagIcon } from 'react-feather';
import useProductSearch from '../hooks/useProductSearch';

const MAX_PRODUCT_ON_LIST = 5;

const iconTagStyle = {
  height: 13,
  width: 13,
  margin: 'auto 0',
  marginRight: 3,
};

const ProductTagSelect = props => {
  const { selectedProduct, onChangeProduct, popularProducts = [] } = props;

  const productInput = useRef(null);
  const [productFilter, setProductFilter] = useState('');

  const { productSearch } = useProductSearch(productFilter);

  const filterProduct = productFilter ? productSearch : popularProducts;

  const filteredProducts =
    filterProduct.length > 0
      ? difference(filterProduct, selectedProduct)
      : difference(productSearch, onChangeProduct);

  const displayProducts = filteredProducts.slice(0, MAX_PRODUCT_ON_LIST);

  return (
    <div className="flex flex-col gap-4 w-full">
      <TagInput
        ref={productInput}
        width="100%"
        inputWidth="55%"
        tags={selectedProduct}
        onTagClick={tag => {
          const tagList = difference(selectedProduct, [tag]);
          onChangeProduct(tagList);
        }}
        inputOnChange={setProductFilter}
        inputValue={productFilter}
        placeholder="(Optional) Tag a relevant product"
      />
      <div className="flex flex-wrap gap-1" data-testid="filter-product-box">
        {displayProducts.map(tag => {
          const formatTitle = (
            <>
              <ProductTagIcon style={iconTagStyle} /> {tag}
            </>
          );
          return (
            <Tag
              onClick={() => {
                const tagList = uniq([...selectedProduct, tag]);
                onChangeProduct(tagList);
                setProductFilter('');
                productInput.current.focus();
              }}
              title={formatTitle}
              key={tag}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductTagSelect;
