import React, { useEffect, useState, useCallback } from 'react';
import buildConfig from 'app/utils/buildConfig';
import searchApi from 'app/api/searchApi';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { X } from 'react-feather';

const trimSearchTerm = (searchTerm = '') => {
  if (searchTerm.length > 1 && searchTerm[searchTerm.length - 1] === '?') {
    searchTerm = searchTerm.substring(0, searchTerm.length - 1);
  }
  return searchTerm.replace(/ /g, '+');
};

const SimilarPostList = (props: {
  searchTerm: string;
  handleClose: () => void;
}) => {
  const { searchTerm, handleClose } = props;
  const [similarPostList, setSimilarPostList] = useState([]);

  const fetchSimilarQuestion = useCallback(
    debounce(searchTermParam => {
      searchApi
        .searchQuestionWithTerm({ term: searchTermParam })
        .then(response => {
          const searchResult = get(response, 'data.data', []);
          setSimilarPostList(searchResult);
        })
        .catch(() => {
          setSimilarPostList([]);
        });
    }, 1000),
    [],
  );

  useEffect(() => {
    if (searchTerm.split(' ').length > 1) {
      fetchSimilarQuestion(searchTerm);
    } else {
      setSimilarPostList([]);
    }
  }, [fetchSimilarQuestion, searchTerm]);

  if (similarPostList.length === 0) return null;

  return (
    <div className="mt-2 mb-4 bg-neutral-300 p-4 rounded">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex w-full justify-between">
          <p className="text-sm font-bold">Similar Discussion</p>
          <X
            size={16}
            color="rgba(64, 72, 90, 1)"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {similarPostList.slice(0, 1).map(question => {
          return (
            <a
              href={`${buildConfig.apiHost}/posts/${question.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              key={question.id}
            >
              <div className="flex flex-col w-full gap-1">
                <p>{question.title}</p>
                <p className="text-neutral-500">
                  {question.commentsCount}{' '}
                  {question.commentsCount < 2 ? 'comment' : 'comments'}
                </p>
              </div>
            </a>
          );
        })}
        <a
          href={`${buildConfig.apiHost}/search?s=${trimSearchTerm(searchTerm)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.track(trackedEvents.CommunityFeed, {
              param: 'see_all_similar_discussion',
            });
          }}
        >
          <p className="text-blue-500">See all similar discussions</p>
        </a>
      </div>
    </div>
  );
};

export default SimilarPostList;
