import { useState, useEffect, useRef } from 'react';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import * as Sentry from '@sentry/nextjs';
import api from 'entity/post/postApi';

const linkify = linkifyIt()
  .tlds(tlds)
  .add('ftp:', null)
  .set({ fuzzyEmail: false });

export default function useLinkPreview(description) {
  const linkPreviewCache = useRef({ url: '', data: '' });
  const [linkPreviewData, setLinkPreviewData] = useState({ image_url: '' });
  const [linkPreviewURL, setlinkPreviewURL] = useState();
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    const matches = linkify.match(description || '');

    if (typeof matches !== 'undefined' && matches !== null) {
      const getAllUrl = matches.map(match =>
        trim(replace(match.url, '\\', '')),
      );

      (async () => {
        if (linkPreviewCache.current.url === getAllUrl[0]) {
          setlinkPreviewURL(linkPreviewCache.current.url);
          setLinkPreviewData(linkPreviewCache.current.data);
        } else {
          try {
            setIsLoading(true);
            const { data } = await api.getLinkPreview({ id: getAllUrl[0] });
            linkPreviewCache.current.url = getAllUrl[0];
            linkPreviewCache.current.data = data;
            setlinkPreviewURL(getAllUrl[0]);
            setLinkPreviewData(data);
            setIsLoading(false);
          } catch (error) {
            Sentry.captureMessage(
              `Failed to display link preview data for ${getAllUrl[0]}`,
            );
            Sentry.captureException(error);
            setIsLoading(false);
          }
        }
      })();
    }

    return () => {
      linkPreviewCache.current = { url: '', data: '' };
      setLinkPreviewData({ image_url: '' });
    };
  }, [description]);

  return {
    loading,
    linkPreviewData,
    linkPreviewURL,
    setLinkPreviewData,
    linkPreviewCache,
  };
}
