import { useState, useEffect } from 'react';

import useDebounce from 'app/utils/hooks/useDebounce';
import searchApi from 'app/api/searchApi';

const useProductSearch = (productFilter = '') => {
  const [productSearch, setProductSearch] = useState([]);

  const searchTerm = useDebounce(productFilter, 600);

  useEffect(() => {
    const fetchSearchedProducts = async () => {
      const { data: searchData } = await searchApi.searchProductWithTerm({
        term: searchTerm,
        perPage: 8,
      });

      const productList = searchData.data.map(product => product.name);

      setProductSearch(productList);
    };

    if (searchTerm.length > 2) {
      fetchSearchedProducts();
    }
  }, [searchTerm]);

  return {
    productSearch,
  };
};

export default useProductSearch;
