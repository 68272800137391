import React from 'react';
import formatCount from 'utils/formatCount';
import Avatar from 'seedly-component-library/avatar-new';

interface OwnProps {
  name: string;
  image: string;
  discussionsCount: number;
}

const GroupPassport = (props: OwnProps) => {
  const { name, image, discussionsCount = undefined } = props;

  return (
    <div className="flex items-center gap-2" data-testid="group-passport">
      <Avatar sizeClass="avatar-10" src={image} />
      <div className="flex flex-col">
        <p className="text-sm font-bold">{name}</p>
        {discussionsCount > 0 && (
          <p className="text-xs text-neutral-500">
            {formatCount(discussionsCount)} discussion
          </p>
        )}
      </div>
    </div>
  );
};

export default GroupPassport;
