import React from 'react';
import styled from 'styled-components';
import { Space } from 'app/seedly-component-library';

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #999;
  margin: 0;

  :checked {
    border: 5px solid ${props => props.theme.colors.seedlyBlue};
  }
`;

interface RadioProps {
  onChange: () => void;
  name: string;
  label: string;
  isChecked?: boolean;
  children: React.ReactNode;
}

const Radio = (props: RadioProps) => {
  const { onChange = () => {}, name, label, isChecked = false } = props;

  return (
    <Space size="sm" onChange={onChange}>
      <StyledInput
        id={`radio-id-${label}`}
        type="radio"
        name={name}
        data-testid={`radio-id-${label}`}
        checked={isChecked}
      />
      <label htmlFor={`radio-id-${label}`}>{props.children}</label>
    </Space>
  );
};

export default Radio;
