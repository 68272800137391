import { useState, useEffect } from 'react';
import postApi from 'entity/post/postApi';
import { isSeedlyEventSlug } from 'entity/group/groupUtils';

const useSeedlyEventTopic = (group = {}) => {
  const [seedlyEventTopics, setSeedlyEventTopics] = useState([]);
  useEffect(() => {
    const fetchSeedlyEventTopics = async () => {
      const response = await postApi.getSeedlyEventTopic(group.slug);
      if (response.ok) {
        const topics = response.data.data || [];
        setSeedlyEventTopics(topics);
      }
    };
    if (isSeedlyEventSlug(group?.slug)) {
      fetchSeedlyEventTopics();
    } else {
      setSeedlyEventTopics([]);
    }
  }, [group.slug]);

  return seedlyEventTopics;
};

export default useSeedlyEventTopic;
