import React from 'react';
import { Tag as ProductTagIcon } from 'react-feather';
import Tag from '../tag';
import { TagTheme } from '../tag/styles';
import * as S from './styles';

const iconTagStyle = {
  height: 13,
  width: 13,
  margin: 'auto 0',
  marginRight: 3,
};

interface TagInputProps {
  tags: string[];
  width: string;
  onTagClick: (e) => void;
  onInputFocus?: (e) => void;
  inputOnChange: (e) => void;
  inputWidth: string;
  inputValue: string;
  placeholder: string;
  tagVariant?: keyof typeof TagTheme;
}

const TagInput = React.forwardRef<HTMLInputElement, TagInputProps>(
  (props, ref) => {
    const {
      tags,
      width,
      onTagClick,
      onInputFocus = () => {},
      inputOnChange = () => {},
      inputWidth,
      inputValue = '',
      placeholder = '',
      tagVariant = 'highlighted',
    } = props;

    return (
      <S.ProductTagWrapper width={width}>
        <S.ProductTagList>
          {tags.map(tag => (
            <Tag
              closable
              onClick={() => onTagClick(tag)}
              title={
                <>
                  <ProductTagIcon style={iconTagStyle} /> {tag}
                </>
              }
              key={tag}
              variant={tagVariant}
            />
          ))}
          <S.TopicInput
            ref={ref}
            width={inputWidth}
            onFocus={() => onInputFocus(true)}
            value={inputValue}
            onChange={e => inputOnChange(e.target.value)}
            data-testid="input-filter-products"
            placeholder={placeholder}
          />
        </S.ProductTagList>
      </S.ProductTagWrapper>
    );
  },
);

export default TagInput;
