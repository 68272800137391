import isEmpty from 'lodash/isEmpty';
import { ruleRunner, run } from 'app/utils/formValidation';
import { isSeedlyEventSlug } from 'entity/group/groupUtils';

const titleValidation = value => {
  let errorMessage = 'Please give a question title';
  const maxTextLength = 200;
  const textLength = value.length;

  if (textLength === 0) {
    return () => errorMessage;
  }

  if (value.length > maxTextLength) {
    errorMessage = `Title cannot be greater than ${maxTextLength} characters. (${
      maxTextLength - textLength
    })`;
    return () => errorMessage;
  }

  return null;
};

const groupValidation = value => {
  return isEmpty(value) ? () => 'Please select a group' : null;
};

const tagsValidation = value => {
  return isEmpty(value) ? () => 'Please select a tag' : null;
};

const fieldValidations = [
  ruleRunner('title', 'Question Title', titleValidation),
  ruleRunner('group', 'Group', groupValidation),
];

export const validator = values => {
  if (isSeedlyEventSlug(values.group?.slug)) {
    return run(values, [
      ...fieldValidations,
      ruleRunner('tags', 'Tag', tagsValidation),
    ]);
  }
  return run(values, fieldValidations);
};
