import styled from 'styled-components';
import { media } from 'theme';
import spacing from '../themes/spacing';
import colors from '../themes/colors';
import fonts from '../themes/fonts';

export const ProductTagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div:nth-child(n + 1) {
    margin-right: ${spacing.xxs};
    margin-top: ${spacing.xxs};
  }
`;

export const ProductTagWrapper = styled.div<{ width: string }>`
  width: ${props => props.width || '450px'};
`;

export const TopicInput = styled.input`
  border: 0px;
  font-size: 14px;
  height: 25px;
  width: ${props => props.width};
  outline: none;

  ::placeholder {
    font: ${fonts.placeholder};
    color: ${colors.neutral5};

    ${media.largeAndDown`
      font-size: 16px;
      overflow: visible;
    `}
  }

  ${media.largeAndDown`
    font-size: 16px;
    width: 100%;
  `}
`;
