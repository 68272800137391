import styled from 'styled-components';
import spacing from '../themes/spacing';
import borderRadius from '../themes/borderRadius';
import colors from '../themes/colors';

export const TagTheme = {
  default: {
    bgColor: colors.neutral2,
    fontColor: colors.neutral5,
    hoveredBgColor: colors.blue2,
    hoveredFontColor: colors.seedlyBlue,
  },
  highlighted: {
    bgColor: colors.seedlyBlue,
    fontColor: colors.neutral1,
    hoveredBgColor: colors.seedlyBlue,
    hoveredFontColor: colors.neutral1,
  },
  green: {
    bgColor: colors.green1,
    fontColor: colors.green5,
    hoveredBgColor: colors.green2,
    hoveredFontColor: colors.green5,
  },
  greenHighlighted: {
    bgColor: colors.green5,
    fontColor: colors.neutral1,
    hoveredBgColor: colors.green5,
    hoveredFontColor: colors.neutral1,
  },
};

export const TagWrapper = styled.div<{
  variant: typeof TagTheme[keyof typeof TagTheme];
}>`
  display: inline-flex;
  box-sizing: border-box;
  border-radius: ${borderRadius.xxxl};
  padding: ${`${spacing.xxs} ${spacing.sm}`};
  width: fit-content;
  height: fit-content;
  background-color: ${props => props.variant.bgColor};
  color: ${props => props.variant.fontColor};
  font-size: 12px;
  cursor: pointer;
  align-items: center;

  &:link,
  &:visited,
  &:focus,
  &:active {
    color: ${props => props.variant.fontColor};
  }

  &:hover {
    background-color: ${props => props.variant.hoveredBgColor};
    color: ${props => props.variant.hoveredFontColor};
  }

  + input::placeholder {
    color: transparent;
  }
`;
