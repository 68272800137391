import React from 'react';
import withWordCount from './withWordCount';
import { StyledTextArea, TextAreaTheme } from './styles';

const TextArea = props => {
  const {
    prefix = null,
    postfix = null,
    isError = false,
    minHeight = '60px',
    wordLeft = undefined,
    theme = 'default',
    resize = 'initial',
    ...otherProps
  } = props;

  const textAreaTheme = TextAreaTheme[theme];

  return (
    <div className="bg-white border-b-neutral-400 border-b border-solid p-0">
      <div className="flex flex-col items-end w-full gap-4">
        <div className="flex gap-2 w-full">
          {prefix}
          <StyledTextArea
            fontTheme={textAreaTheme}
            resize={resize}
            minheight={minHeight}
            {...otherProps}
          />
          {postfix}
        </div>
        {wordLeft && withWordCount(wordLeft, isError)}
        {props.children}
      </div>
    </div>
  );
};

export default TextArea;
