import React from 'react';
import noop from 'lodash/noop';
import { X as CloseIcon } from 'react-feather';
import * as S from './styles';

const iconStyle = {
  height: 13,
  width: 13,
  color: 'white',
  margin: 'auto 0',
  marginLeft: 4,
};

interface TagProps {
  title: JSX.Element;
  renderAs?: React.ElementType;
  onClick?: () => void;
  variant?: keyof typeof S.TagTheme;
  closable?: boolean;
}

const Tag = (props: TagProps) => {
  const {
    title,
    renderAs = 'div',
    onClick = noop,
    variant,
    closable,
    ...otherProps
  } = props;

  const theme = S.TagTheme[variant] || S.TagTheme.default;

  return (
    <S.TagWrapper
      variant={theme}
      id="filter-topic"
      data-testid={`${title}-tag`}
      as={renderAs}
      onClick={onClick}
      {...otherProps}
    >
      {title}
      {closable && <CloseIcon style={iconStyle} />}
    </S.TagWrapper>
  );
};

export default Tag;
