import React from 'react';
import styled from 'styled-components';
import Text from '../../text';

import {
  getTotalWordCount,
  getMaximumWordCount,
  displayErrorMessage,
} from './helper';

export const WordCount = styled.div`
  text-align: end;
  padding-bottom: 8px;
`;

export default function withWordCount(wordLeft, isError) {
  const wordLeftDesc = 'characters';
  const textIntent = isError ? 'error' : 'secondary';

  const totalWordCount = getTotalWordCount(wordLeft);
  const maxWordCount = getMaximumWordCount(wordLeft);

  const helperText = isError
    ? `${displayErrorMessage(totalWordCount, maxWordCount, wordLeftDesc)}`
    : `${wordLeft} ${wordLeftDesc}`;

  return (
    <WordCount>
      <Text intent={textIntent}>{helperText}</Text>
    </WordCount>
  );
}
