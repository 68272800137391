import { stateToMarkdown } from 'draft-js-export-markdown';
import { formatBase64Picture } from 'app/utils/imageHelper';

/**
 * @param {Object} values post data from form
 * @returns {Object} post data to be sent to server
 * @description Creates a shallow copy and transforms the form data values to a format that can be sent to the server
 */
const transformFormValues = values => {
  const transformedValues = { ...values };

  const description = stateToMarkdown(
    transformedValues.description.getCurrentContent(),
  );
  const isEmptyDescription =
    description.trim().length === 1 &&
    /[\u200B-\u200D\uFEFF]/g.test(description);

  if (transformedValues.picture) {
    const formatPicture = formatBase64Picture(transformedValues.picture);
    transformedValues.picture = formatPicture;
  }

  if (isEmptyDescription) {
    transformedValues.description = '';
  } else {
    transformedValues.description = description;
  }

  return transformedValues;
};

export default transformFormValues;
