import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { Message } from 'app/seedly-component-library';
import { convertFileToBase64, verifyFileSize } from 'app/utils/imageHelper';

export default function InputImage(props) {
  const { inputFile, setFieldValue } = props;
  const handleClick = () => {
    Sentry.addBreadcrumb({
      category: 'Image',
      message: 'User uploading image for post',
      level: Sentry.Severity.Info,
    });
    inputFile.current.value = null; // Allows user to upload same file
  };

  const handleImageUpload = async e => {
    const imageFile = e.target.files[0];
    const isAcceptedFileSize = verifyFileSize(imageFile);
    if (imageFile && isAcceptedFileSize) {
      try {
        const base64image = await convertFileToBase64(imageFile);
        const base64ToURL = URL.createObjectURL(imageFile);
        setFieldValue('picture', base64image);
        setFieldValue('picturePreview', base64ToURL);
      } catch (error) {
        Sentry.addBreadcrumb({
          category: 'Image',
          message: 'User failed to upload Image for post',
          level: Sentry.Severity.Error,
        });
        Sentry.captureException(error);
        Sentry.showReportDialog();
        setFieldValue('picture', null);
        setFieldValue('picturePreview', null);
      }
    } else {
      Message.error('Please upload an image smaller than 5MB');
    }
  };

  return (
    <input
      type="file"
      id="file"
      ref={inputFile}
      onClick={handleClick}
      onChange={e => handleImageUpload(e)}
      accept="image/*"
      style={{ display: 'none' }}
    />
  );
}
