import React, { useEffect, useState } from 'react';
import trim from 'lodash/trim';
import lowerFirst from 'lodash/lowerFirst';
import { stateToMarkdown } from 'draft-js-export-markdown';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { Spinner } from 'app/seedly-component-library';
import LinkPreview from 'app/seedly-component-library/image/LinkPreview';
import TextEditor from 'app/components/post/TextEditor';
import useLinkPreview from 'app/utils/hooks/useLinkPreview';
import clsx from 'clsx';
import { XCircle } from 'react-feather';

const linkify = linkifyIt()
  .tlds(tlds)
  .add('ftp:', null)
  .set({ fuzzyEmail: false });

const Description = props => {
  const [linkPreview, setLinkPreview] = useState([]);
  const { values, setFieldValue } = props;
  const {
    linkPreviewData,
    setLinkPreviewData,
    loading,
    linkPreviewCache,
  } = useLinkPreview(linkPreview[0] || '');
  const {
    url: linkPreviewURL,
    image_url: linkPreviewImgURL,
    title,
  } = linkPreviewData;
  const isCoreLinkData = Boolean(linkPreviewURL && linkPreviewImgURL && title);

  const handleTextChange = text => {
    const contentState = text.getCurrentContent();
    const plainMarkdown = stateToMarkdown(contentState);

    if (contentState.hasText()) {
      const allUrl = [];
      const matches = linkify.match(plainMarkdown);
      if (typeof matches !== 'undefined' && matches !== null) {
        matches.forEach(match => allUrl.push(trim(lowerFirst(match.url))));
      }
      setLinkPreview(() => allUrl);
      setFieldValue('description', text);
    } else {
      setLinkPreview([]);
      setFieldValue('description', text);
      setLinkPreviewData({ image_url: '' });
      linkPreviewCache.current.url = '';
    }
  };

  useEffect(() => {
    // removes data from link preview if someone uploads an image
    if (values.picturePreview !== linkPreview[0]) {
      setLinkPreviewData({ image_url: '' });
      linkPreviewCache.current.data = '';
    }
  }, [values.picturePreview]);

  return (
    <div className="w-full pb-4 border-b border-neutral-400">
      <TextEditor
        editorState={values.description}
        placeholder="(Optional) Add a description"
        name="description"
        onChange={text => handleTextChange(text)}
        data-testid="input-question-description"
        borderStyle={null}
        placeHolderPadding={null}
        contentPadding={null}
        minHeight="90px"
      />
      {loading && <Spinner />}
      {(values.picturePreview || linkPreviewURL) && (
        <div className="mt-4 h-fit w-fit relative rounded-xl border bg-neutral-400">
          {values.picturePreview && (
            <XCircle
              className="fill-neutral-700 stroke-neutral-200 absolute z-10 right-3 top-3 cursor-pointer"
              size={20}
              onClick={() => {
                setFieldValue('picturePreview', null);
                setFieldValue('picture', null);
                setLinkPreview([]);
                linkPreviewCache.current.url = '';
              }}
            />
          )}
          {(values.picturePreview || isCoreLinkData) && (
            <img
              className={clsx(
                'w-full object-cover',
                linkPreviewData.image_url ? 'rounded-t-xl' : 'rounded-xl',
              )}
              alt="preview"
              src={values.picturePreview || linkPreviewImgURL}
            />
          )}
          {isCoreLinkData && <LinkPreview {...linkPreviewData} />}
        </div>
      )}
    </div>
  );
};

export default Description;
