import React, { useState, useRef, useEffect } from 'react';
import { TagInput, Tag } from 'app/seedly-component-library';
import { getSeedlyEventPlaceholder } from 'entity/group/groupUtils';
import styled from 'styled-components';

const MAX_TOPIC_ON_LIST = 5;

const TagList = styled.div`
  > div:nth-child(n + 1) {
    margin-right: ${props => props.theme.spacing.xxs};
    margin-top: ${props => props.theme.spacing.xxs};
  }
`;

const TopicTagSelect = props => {
  const { selectedTopic = [], onChangeTopics, topics = [], group } = props;
  const topicInput = useRef(null);
  const [topicFilterTerm, setTopicFilterTerm] = useState('');
  const [filteredTopics, setFilteredTopics] = useState([]);
  useEffect(() => {
    if (topicFilterTerm) {
      setFilteredTopics(
        topics
          .map(topic => topic.name)
          .filter(topic => topic !== selectedTopic[0])
          .filter(topic => {
            return topic.toLowerCase().includes(topicFilterTerm.toLowerCase());
          })
          .slice(0, MAX_TOPIC_ON_LIST),
      );
    } else {
      setFilteredTopics(
        topics
          .map(topic => topic.name)
          .slice(0, 1)
          .filter(topic => topic !== selectedTopic[0]),
      );
    }
  }, [topicFilterTerm, topics, selectedTopic]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <TagInput
        ref={topicInput}
        width="100%"
        inputWidth="55%"
        tags={selectedTopic}
        onTagClick={() => {
          onChangeTopics([]);
        }}
        inputOnChange={setTopicFilterTerm}
        inputValue={topicFilterTerm}
        tagVariant="greenHighlighted"
        placeholder={getSeedlyEventPlaceholder(group?.slug)}
      />
      <TagList>
        {filteredTopics.map(tag => {
          return (
            <Tag
              onClick={() => {
                topicInput.current.focus();
                setTopicFilterTerm('');
                onChangeTopics([tag]);
              }}
              title={tag}
              key={tag}
              variant="green"
            />
          );
        })}
      </TagList>
    </div>
  );
};

export default TopicTagSelect;
