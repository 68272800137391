/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Info, ChevronDown, Image } from 'react-feather';
import get from 'lodash/get';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { Checkbox } from 'seedly-component-library';
import Avatar from 'seedly-component-library/avatar-new';
import Button from 'seedly-component-library/button-new';
import { profileImages } from 'seedly-component-library/avatar-new/helper';
import { TextArea } from 'seedly-component-library/beta';
import Dialog from 'seedly-component-library/dialog';

import {
  removeLeavePageEventListener,
  addLeavePageEventListener,
} from 'utils/jsWarningPrompt';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { CommunityPageContext } from 'pageContents/CommunityContent/context/CommunityPageContext';
import { LOGIN_MODAL, TYPES } from 'constants/login';
import ConfirmationModal from 'components/post/ConfirmationModal';
import {
  closePostModal,
  openPostModal,
  openLoginModal,
} from 'dispatcher/modalDispatcher';

import {
  emptyEditorContentState,
  contentEditedState,
} from 'utils/markdownFormat';
import { createPostRequest, updatePostRequest } from 'entity/post/postRedux';
import { isSeedlyEventSlug } from 'entity/group/groupUtils';
import { useSelectGroupById } from 'entity/group/groupHooks';

import TipModal from 'components/post/TipModal';
import { ANON_TYPE } from 'constants/app';
import { RootState } from 'reducer';
import { profileImage } from 'theme';
import { validator } from './validation';
import SimilarPostList from './SimilarPostList';
import ProductTagSelect from './ProductTagSelect';
import TopicTagSelect from './TopicTagSelect';
import transformFormValues from './utils/transformFormValues';
import InputImage from './InputImage';
import usePopularProducts from './hooks/usePopularProducts';
import useSeedlyEventTopic from './hooks/useSeedlyEventTopic';
import Description from './Description';
import GroupModal from './GroupModal';

const makeInitialState = ({
  presetPost,
  presetProducts,
  presetGroup,
  presetDescription,
}) => {
  return {
    title: presetPost || '',
    description: presetDescription
      ? contentEditedState(presetDescription, 'post-description')
      : emptyEditorContentState('post-description'),
    products: presetProducts || [],
    isAnonymous: false,
    group: presetGroup || '',
    tags: [],
  };
};

const mapPostToState = post => {
  const seedlyEventName = get(post, 'post.seedlyeventList[0].name', undefined);
  const productsList = get(post, 'post.productsList', []);
  const productNames = productsList.map(product => product.name);

  return {
    id: post.id,
    title: post.title,
    description: contentEditedState(post.description, 'post-description'),
    products: productNames,
    isAnonymous: post.isAnonymous,
    picturePreview: post.picture,
    group: post.group,
    tags: seedlyEventName ? [seedlyEventName] : [],
  };
};

const CreatePostForm = () => {
  const dispatch = useDispatch();

  const [isTipModalOpen, setTipModalOpen] = useState(false);

  const inputFile = useRef(null);

  const {
    presetPost,
    presetProducts,
    post: editedPost,
    isEdit,
    presetGroup,
    presetDescription,
  } = useSelector((state: RootState) => state.post.form);
  const isPostModalOpen = useSelector(
    (state: RootState) => state.modal.isPostQuestionModalOpen,
  );
  const isSignedIn = useSelector((state: RootState) =>
    get(state.auth.session, 'isSignedIn', false),
  );
  const { name, image } = useSelector((state: RootState) => state.auth.user);

  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const [isSimilarPostOpen, setSimilarPostOpen] = useState(true);

  const handleCloseModal = (dirty, resetForm) => {
    if (dirty) {
      ConfirmationModal({
        title: 'Close form?',
        content: 'Changes that you made may not be saved.',
        okText: 'Confirm',
        cancelText: 'Cancel',
      }).then(
        () => {
          dispatch(closePostModal());
          resetForm();
        },
        () => dispatch(openPostModal()),
      );
    } else {
      dispatch(closePostModal());
    }
  };

  const { groupId, pageType } = useContext(CommunityPageContext);

  const currentGroup = useSelectGroupById(groupId);
  const popularProducts = usePopularProducts();

  const postState = useMemo(() => mapPostToState(editedPost), [editedPost]);
  const initialValues = isEdit
    ? postState
    : makeInitialState({
        presetPost,
        presetProducts,
        presetGroup,
        presetDescription,
      });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: validator,
    onSubmit: (value, formikBag) => {
      const transformedValue = transformFormValues(value);

      if (isEdit) {
        dispatch(
          updatePostRequest({
            ...transformedValue,
            onSuccess: () => dispatch(closePostModal()),
          }),
        );
      } else if (!isSignedIn) {
        sessionStorage.setItem(
          'new-question',
          JSON.stringify(transformedValue),
        );
        dispatch(openLoginModal(LOGIN_MODAL[TYPES.CREATE_POST]));
      } else {
        dispatch(
          createPostRequest({
            ...transformedValue,
            onSuccess: () => dispatch(closePostModal()),
          }),
        );
      }

      formikBag.resetForm();
    },
  });

  const {
    values,
    setFieldValue,
    validateForm,
    dirty: isDirty,
    errors,
    isSubmitting,
    resetForm,
    handleSubmit,
    handleChange,
  } = formik;

  const seedlyEventTopics = useSeedlyEventTopic(values.group);

  const maxTitleWords = 200;
  let imgSource;

  if (isSignedIn) {
    if (values.isAnonymous) {
      imgSource = profileImage.anonymousPoster;
    } else {
      imgSource = image;
    }
  } else {
    imgSource = profileImage.defaultImage;
  }

  useEffect(() => {
    validateForm();
    addLeavePageEventListener(isDirty, isEdit);

    return () => {
      removeLeavePageEventListener();
    };
  }, [isDirty, values.title]);

  useEffect(() => {
    if (isEdit) {
      setFieldValue('group', values.group);
    } else if (pageType === 'group' && groupId) {
      setFieldValue('group', currentGroup);
    } else {
      setFieldValue('group', {});
    }
  }, [groupId, currentGroup?.id]);

  return (
    <Dialog
      isOpen={isPostModalOpen}
      onCloseClick={() => {
        handleCloseModal(isDirty, resetForm);
      }}
      headerTitle={isEdit ? 'Edit Post' : 'Start a Discussion'}
      contentClassName="p-0"
      headerClassName="p-4 border-b border-neutral-400"
    >
      <div className="flex flex-col gap-4 w-full max-h-[500px] overflow-y-auto p-4">
        <GroupModal
          isOpen={isGroupModalOpen}
          setGroupModalOpen={setGroupModalOpen}
          values={values}
          setFieldValue={setFieldValue}
        />
        <div className="flex items-center">
          <Avatar
            src={
              values?.isAnonymous
                ? profileImages[ANON_TYPE.QUESTION].img
                : imgSource
            }
            name={name}
          />
          <div
            className="flex items-center ml-4 cursor-pointer"
            onClick={() => setGroupModalOpen(true)}
          >
            {isEmpty(values.group) ? (
              <span>Select a group</span>
            ) : (
              <>
                <p className="text-sm font-semibold">Post to</p>
                <Avatar
                  sizeClass="avatar-6"
                  src={values.group.profileImage}
                  name={values.group.slug}
                  className="ml-2"
                />
                <p className="ml-2 text-xs text-left">{values.group.name}</p>
              </>
            )}
            {!isEdit && (
              <ChevronDown
                data-testid="arrow-select-group"
                size={16}
                style={{
                  cursor: 'pointer',
                  margin: `${
                    isEmpty(values.group) ? '4px 0 0 4px' : '0 0 0 6px'
                  }`,
                }}
              />
            )}
          </div>
        </div>
        <TextArea
          isError={values?.title?.length > maxTitleWords}
          placeholder="Write a title"
          name="title"
          value={values?.title}
          onChange={handleChange}
          wordLeft={`${values?.title?.length}/${maxTitleWords}`}
          theme="titlelg"
          data-testid="input-question-text"
          minheight="30px"
          maxRows={10}
        >
          {isSimilarPostOpen && (
            <SimilarPostList
              searchTerm={values?.title}
              handleClose={() => {
                mixpanel.track(trackedEvents.CommunityFeed, {
                  param: 'close_similar_discussion',
                });
                setSimilarPostOpen(false);
              }}
            />
          )}
        </TextArea>
        <Description values={values} setFieldValue={setFieldValue} />
        <ProductTagSelect
          selectedProduct={values?.products}
          onChangeProduct={products => {
            setFieldValue('products', products);
          }}
          popularProducts={popularProducts}
        />
        {isSeedlyEventSlug(values.group?.slug) && (
          <>
            <div className="horizontal-divider" />
            <TopicTagSelect
              selectedTopic={values?.tags}
              topics={seedlyEventTopics}
              onChangeTopics={tags => {
                setFieldValue('tags', tags);
              }}
              group={values.group}
            />
          </>
        )}
      </div>
      <div className="flex flex-col w-full items-center">
        <div
          className="px-4 py-2 bg-blue-100 w-full cursor-pointer"
          onClick={() => setTipModalOpen(true)}
        >
          <div className="flex justify-between w-full items-center">
            <p className="text-blue-500">Learn how to style your text</p>
            <Info className="text-blue-400" size={18} />
          </div>
        </div>
        <div className="flex justify-between w-full p-3 border-t border-neutral-400">
          <div className="flex gap-2 items-center">
            <Image
              className="cursor-pointer"
              size={24}
              onClick={() => {
                inputFile.current.click();
              }}
            />
            <Checkbox
              name="isAnonymous"
              labelSpace="xxs"
              checked={values.isAnonymous}
              onChange={e => {
                setFieldValue('isAnonymous', e.target.checked);
              }}
              disabled={isEdit}
            >
              Post anonymously
            </Checkbox>
          </div>
          <Button
            disabled={!isEmpty(errors) || isSubmitting}
            onClick={!isEmpty(errors) || isSubmitting ? noop : handleSubmit}
            data-testid="button-post-question"
          >
            {isEdit ? 'Update' : 'Post'}
          </Button>
        </div>
      </div>
      <InputImage inputFile={inputFile} setFieldValue={setFieldValue} />
      <TipModal isOpen={isTipModalOpen} setTipModalOpen={setTipModalOpen} />
    </Dialog>
  );
};

export default CreatePostForm;
