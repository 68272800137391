import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { media } from 'theme';
import colors from '../../themes/colors';

export const TextAreaTheme = {
  default: {
    fontSize: 'inherit',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  titlelg: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
};

export const StyledTextArea = styled(TextareaAutosize)`
  font-family: inherit;
  font-size: ${props => props.fontTheme.fontSize};
  font-weight: ${props => props.fontTheme.fontWeight};
  line-height: ${props => props.fontTheme.lineHeight};
  border: none;
  width: 100%;
  min-height: ${props => props.minheight};
  outline: ${props => !props.outline && 'none'};
  resize: ${props => props.resize};

  ::placeholder {
    color: ${colors.neutral5};
    font-size: ${props => props.fontTheme.fontSize};
    font-weight: ${props => props.fontTheme.fontWeight};

    ${media.largeAndDown`
      font-size: 16px;
    `}
  }

  ${media.largeAndDown`
    font-size: 16px;
  `}
`;
