import React from 'react';
import lowerFirst from 'lodash/lowerFirst';
import truncate from 'lodash/truncate';

export default function LinkPreview({ title, description, url }) {
  return (
    <div className="p-3">
      {title && <p variant="titlemd">{title}</p>}
      {description && (
        <p className="pt-0 pb-2 text-neutral-600">
          {truncate(description, { length: 150 })}
        </p>
      )}
      {url && <p className="text-neutral-500 text-xs">{lowerFirst(url)}</p>}
    </div>
  );
}
